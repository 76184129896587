const HOST = process.env.REACT_APP_API_HOST || 'http://localhost:8080';

export const fetchCollection = async ({ pageParam, queryKey }) => {
  const [, params] = queryKey;
  const page = pageParam || 1;
  const queryString = new URLSearchParams({ ...params, page });

  const response = await fetch(`${HOST}/people?${queryString}`);
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return await response.json();
}

export const downloadCsvUrl = ({ pageParams = [], ...params }) => {
  const page = pageParams[pageParams.length - 1] || 1;
  const queryString = new URLSearchParams({ ...params, page });
  return `${HOST}/people.csv?${queryString}`;
}
