import { useEffect, useState } from "react";
import { useHistory } from "./history";

// https://usehooks.com/useDebounce/
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useQueryState(query, defaultValue) {
  const history = useHistory();
  const [value, setValue] = useState(new URLSearchParams(history.location.search).get(query) || defaultValue);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set(query, value);
    history.push(`${history.location.pathname}?${searchParams}`);
  }, [history, query, value]);

  return [value, setValue];
}
