import { useInfiniteQuery } from "react-query";
import { Button, Container, VStack } from '@chakra-ui/react'
import { DownloadIcon } from '@chakra-ui/icons';

import { DataTable } from "./DataTable";
import { RegionSelect } from "./RegionSelect";
import { downloadCsvUrl, fetchCollection } from "./api";
import { useQueryState } from './hooks';
import { InputField } from "./InputField";
import { generateSeed } from "./utils";
import { SliderField } from "./SliderField";

export const App = () => {
  const [region, setRegion] = useQueryState('region', 'us');
  const [mistakes, setMistakes] = useQueryState('mistakes', '0');
  const [seed, setSeed] = useQueryState('seed', () => generateSeed());
  const { data, error, isLoading, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['people', { region, seed, mistakes }],
    fetchCollection,
    {
      getNextPageParam: (lastPage) => lastPage.meta.page + 1,
    }
  );
  const downloadUrl = downloadCsvUrl({ region, seed, mistakes, pageParams: data?.pageParams });

  const resetSeed = () => {
    setSeed(generateSeed());
  }

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <Container maxW='container.xl'>
      <Container>
        <VStack spacing={5} py={5}>
          <RegionSelect
            value={region}
            disabled={isLoading}
            onChange={setRegion}
          />
          <SliderField
            value={mistakes}
            disabled={isLoading}
            onChange={setMistakes}
          />
          <InputField
            value={seed}
            disabled={isLoading}
            onChange={setSeed}
            onReset={resetSeed}
          />
          {!isLoading && (
            <Button
              as="a"
              href={downloadUrl}
              download
              leftIcon={<DownloadIcon />}
              colorScheme='teal'
              variant='solid'
            >
              Download CSV
            </Button>
          )}
        </VStack>
      </Container>
      <DataTable
        collection={data?.pages}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        isPageLoading={isFetchingNextPage}
      />
    </Container>
  );
}
