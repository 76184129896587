import { createContext, useContext, useEffect, useState } from "react";

const HistoryContext = createContext(null);

export const HistoryProvider = ({ history, children }) => {
  const [location, setLocation] = useState(history.location);

  useEffect(() => {
    let unlisten = history.listen(({ location, action }) => {
      setLocation(location);
    });

    return () => unlisten();
  }, [history]);

  return (
    <HistoryContext.Provider value={{ history, location }}>
      {children}
    </HistoryContext.Provider>
  )
};

export const useHistory = () => useContext(HistoryContext).history;
