import { FormControl, FormLabel, Select } from '@chakra-ui/react'

export const RegionSelect = ({ value, disabled = false, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  };

  return (
    <FormControl>
      <FormLabel>Region</FormLabel>
      <Select
        placeholder='Select region'
        value={value}
        disabled={disabled}
        onChange={handleChange}
      >
        <option value='us'>United States</option>
        <option value='ru'>Russia</option>
      </Select>
    </FormControl>
  )
}
