import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Center,
  Box,
  Tag,
} from '@chakra-ui/react'

export const DataTable = ({ collection = [], fetchNextPage, isLoading, isPageLoading }) => {
  const rowsCount = collection.reduce((sum, group) => sum + group.data.length, 0);
  const description = isPageLoading ? 'Loading...' : 'Random people data';

  if (isLoading) return (
    <Center h="calc(100vh - 120px)">
      <Spinner size="xl" />
    </Center>
  );

  return (
    <>
      <Center pt={10} pb={5}>
        <Tag>{rowsCount} records</Tag>
      </Center>
      <InfiniteScroll
        dataLength={rowsCount}
        loader={<Box p="5" textAlign="center"><Spinner /></Box>}
        next={fetchNextPage}
        hasMore
        style={{overflowAnchor: 'none'}} // https://lightrun.com/answers/danbovey-react-infinite-scroller-chrome-56-automatically-scrolls-to-the-bottom-of-the-page
      >
        <TableContainer>
          <Table variant='simple'>
            <TableCaption>{description}</TableCaption>
            <Thead>
              <Tr>
                <Th isNumeric>#</Th>
                <Th>ID</Th>
                <Th>Full name</Th>
                <Th>Address</Th>
                <Th>Phone</Th>
              </Tr>
            </Thead>
            <Tbody>
              {collection.map((group, i) => (
                <Fragment key={i}>
                  {group.data.map((entry, index) => (
                    <Tr key={entry.id}>
                      <Td isNumeric>{i * group.data.length + index + 1 + (i > 0 ? 10 : 0)}</Td>
                      <Td>{entry.id}</Td>
                      <Td>{entry.fullName}</Td>
                      <Td>{entry.address}</Td>
                      <Td>{entry.phone}</Td>
                    </Tr>
                  ))}
                </Fragment>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  )
}
