import { useEffect, useState } from "react";
import {
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import { useDebounce } from './hooks';

export const SliderField = ({ value, disabled, onChange }) => {
  const [innerValue, setInnerValue] = useState(value);
  const debouncedValue = useDebounce(innerValue, 800);

  const handleChange = (value) => {
    setInnerValue(value);
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <FormControl>
      <FormLabel>Mistakes</FormLabel>
      <Flex>
        <NumberInput
          maxW='100px'
          mr='2rem'
          min={0}
          max={1000}
          step={0.25}
          value={innerValue}
          onChange={handleChange}
        >
          <NumberInputField disabled={disabled} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Slider
          flex='1'
          focusThumbOnChange={false}
          min={0}
          max={10}
          step={0.25}
          value={innerValue}
          onChange={handleChange}
          isDisabled={disabled}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb fontSize='sm' boxSize='32px' children={innerValue} />
        </Slider>
      </Flex>
    </FormControl>
  )
}
