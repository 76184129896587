import { useEffect, useState } from 'react';
import { Button, FormControl, FormLabel, Input, HStack } from '@chakra-ui/react'

import { useDebounce } from './hooks';

export const InputField = ({ value, disabled, onChange, onReset }) => {
  const [innerValue, setInnerValue] = useState(value);
  const debouncedValue = useDebounce(innerValue, 800);

  const handleChange = (event) => {
    setInnerValue(event.target.value);
  };

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  return (
    <FormControl>
      <FormLabel>Seed</FormLabel>
      <HStack>
        <Input
          placeholder='Input seed'
          value={innerValue}
          disabled={disabled}
          onChange={handleChange}
        />
        <Button
          colorScheme='blue'
          isLoading={disabled}
          onClick={onReset}
        >Random seed</Button>
      </HStack>
    </FormControl>
  )
}
